import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

// PrimeVue
import PrimeVue from "primevue/config"
import ToastService from "primevue/toastservice"
import ConfirmationService from "primevue/confirmationservice"
import "primevue/resources/primevue.min.css" // Core css
import "primeicons/primeicons.css" // Icons
import "primeflex/primeflex.css" // PrimeFlex utility plugin for primevue
import Tooltip from "primevue/tooltip"
import "primevue/resources/themes/lara-light-blue/theme.css"

// Custom theme
import "@/assets/customised-theme.css"

// Font awesome
import "@fortawesome/fontawesome-free/css/all.min.css"

// Method for chris to switch API endpoints on built versions of the app hosted on AWS
const fetchData = async () => {
    const resp = await fetch("/kstore.config.json")
    //console.log(await resp.json())
    let backendAPI = await resp.json()
    //console.log(backendAPI.APP_API_URL);
    window.baseUrl = backendAPI.APP_API_URL
    createApp(App)
        .use(store)
        .use(router)
        .use(PrimeVue, { ripple: true })
        .use(ToastService)
        .use(ConfirmationService)
        .directive("tooltip", Tooltip)
        .mount("#app")
}
fetchData()

