<template>
  <div class="flex justify-content-center align-items-center testEnvironmentDiv">
    <h2 class="my-1"><i class="fas fa-warning"/> TEST ENVIRONMENT</h2>
  </div>
</template>

<script>
export default {
  name: "TestEnvironmentBanner"
}
</script>

<style scoped>
.testEnvironmentDiv {
  background-color: #EF5350;
  color: whitesmoke;
}
</style>